body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark-background–primary) !important;
}

html {
  /* --dark-background–primary: #0f1216; */
  --dark-background–primary: #0d1117;
  --dark-background–secondary: #161c22;
  --dark-background–tertiary: #22262c;
  --dark-background–fourth: #b2b6bc;
  --dark-topnav-primary: #161a1f;
  --dark-text-primary: #f3f3f3;
  --dark-text-secondary: #f3f3f3;
  --dark-text-tertiary: #acacac;
  --dark-background–button-primary: #3978f6;
  --dark-theme-link-color: #8cb4ff;
  --dark-input-background: rgba(75, 75, 75, 1);
  --dark-charts-tick-color: #787c8b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bottom-item {
  margin-right: 40px;
}

.top13 {
  margin-left: 1rem;
}
.top13-right {
  margin-right: 1rem;
}

.dark-theme .top10 {
  background-color: var(--dark-background–primary);
}
.dark-theme .top11 {
  background-color: transparent;
}
.dark-theme .top12 {
  background-color: var(--dark-topnav-primary);
}

.dark-theme .top12 div {
  color: var(--dark-text-primary) !important;
}

.dark-theme .tabs-wrapper {
  background-color: var(--dark-background–secondary);
}

.dark-theme .tabs-wrapper .tab-item {
  /* background-color: var(--dark-background–tertiary); */
  color: var(--dark-text-primary) !important;
}

.dark-theme .content-item {
  /* background-color: var(--dark-background–secondary); */
  background-color: transparent;
}

.dark-theme span {
  /* background-color: var(--dark-background–secondary); */
  background-color: transparent;
  color: var(--dark-text-secondary);
}

.dark-theme .session-item {
  background: var(--dark-background–secondary);
}

/* .dark-theme .dt-expception { */
/* color: var(--dark-background–primary); */
/* } */

.dark-theme .heading {
  /* background-color: var(--dark-background–secondary); */
  background-color: transparent;
  color: var(--dark-text-secondary);
}

.dark-theme .normal-text {
  color: var(--dark-text-tertiary);
}

.dark-theme .patient-item,
.dark-theme .gateway-item,
.dark-theme .bracelet-item,
.dark-theme .misc-item,
.dark-theme .compliance-item,
.dark-theme .group-item {
  background: var(--dark-background–secondary);
  color: var(--dark-text-primary);
}

.dark-theme .item-misc {
  color: var(--dark-text-primary);
  opacity: 1 !important;
}

.dark-theme .item-row {
  background-color: var(--dark-background–fourth);
}

.dark-theme .second-bg {
  background: var(--dark-background–secondary);
}
.dark-theme .third-bg {
  background: var(--dark-background–tertiary);
}

.dark-theme .export-ppg {
  color: var(--dark-theme-link-color) !important;
}

.dark-theme .group-name {
  color: var(--dark-text-primary);
}

.dark-theme .patient-item-bottom {
  background-color: var(--dark-background–primary);
  color: var(--dark-text-primary);
}

.dark-theme .selector {
  background-color: var(--dark-background–secondary);
  color: var(--dark-text-primary);
}

.dark-theme .info-placeholder {
  background: var(--dark-background–tertiary);
}

.dark-theme .user-edit-button,
.dark-theme .add-button {
  background-color: var(--dark-background–button-primary);
}

.dark-theme .patient-name {
  color: var(--dark-text-primary);
}

.dark-theme .chatgpt-label,
.dark-theme .export-label {
  color: var(--dark-text-secondary);
}
.dark-theme .info-date,
.dark-theme .info-birth,
.dark-theme .info-height,
.dark-theme .info-weight,
.dark-theme .info-gender {
  color: var(--dark-text-primary);
}

.dark-theme .statistics-heading,
.dark-theme .compliance-heading {
  color: var(--dark-text-secondary);
}

.dark-theme .setting-label {
  color: var(--dark-text-secondary);
  /* background: var(--dark-background–tertiary); */
}

.dark-theme .setting-label-deep {
  opacity: 1 !important;
}

.dark-theme .brace-select__control,
.dark-theme .brace-select-container,
.dark-theme .brace-select__menu,
.dark-theme .brace-select__menu-list {
  background: var(--dark-background–secondary);
  color: var(--dark-text-tertiary);
  font-weight: 500;
}

.dark-theme .brace-select__value-container,
.dark-theme .brace-select__single-value,
.dark-theme .brace-select__input-container {
  background-color: transparent;
  color: white !important;
}

.dark-theme .push-selector__control,
.dark-theme .push-selector-container,
.dark-theme .push-selector__menu,
.dark-theme .push-selector__menu-list {
  background: var(--dark-background–secondary);
  color: var(--dark-text-primary);
}

.dark-theme .push-selector__value-container,
.dark-theme .push-selector__single-value,
.dark-theme .push-selector__input-container {
  background-color: transparent;
  color: white !important;
}

.dark-theme .search-input-wrapper {
  /* background: var(--dark-input-background); */
  background-color: transparent;
}

.dark-theme .search-input {
  /* background: var(--dark-input-background); */
  background-color: transparent;
  color: white;
}

.dark-theme .drop-down {
  background: var(--dark-background–tertiary);
  /* color: var(--dark-text-tertiary); */
}

.dark-theme .dropdown-item {
  font-weight: 500;
  color: var(--dark-text-primary);
}

.dark-theme .priority-item,
.dark-theme .all-patient-priority-item {
  background-color: transparent;
  color: white;
}

.dark-theme .all-patient-priority-square {
  color: white;
  background-color: white;
}

.dark-theme .setting-top-heading {
  color: var(--dark-text-primary);
}

.dark-theme .setting-top-section {
  color: var(--dark-text-primary);
}

.dark-theme .setting-text-active {
  color: var(--dark-text-primary) !important;
  opacity: 0.85 !important;
}

.dark-theme .setting-text-inactive {
  color: var(--dark-text-primary);
  opacity: 0.45;
}
.theme-top-swtich {
  margin-right: 10px;
}

.dark-theme .ico-priority-0,
/* .dark-theme .param-icon, */
.dark-theme .ico-priority-none,
.dark-theme .ico-priority-undefined {
  filter: brightness(1000%);
}
.dark-theme .param-value,
.dark-theme .param-label {
  filter: brightness(1000%);
}

.dark-theme [class^="ico-priority-"] {
  filter: brightness(1000%);
}

.dark-theme .ico-priority-8 {
  /* filter: brightness(80%); */
}

.dark-theme .too-long-logo,
.dark-theme .bottom-bell-icon,
.dark-theme .battery-icon,
.dark-theme .chevron-icon,
.dark-theme .calender-image,
.dark-theme .setting-bell-icon {
  filter: invert(775%) brightness(1000%);
}

.dark-theme .patient-info-icon,
.dark-theme .group-info-icon {
  filter: brightness(0.1) invert(100%);
}

.dark-theme .patient-statistics-content {
  background-color: var(--dark-background–tertiary);
}

.dark-theme .patient-statistics-heading {
  color: var(--dark-text-primary);
}

.dark-theme .activity-box {
  color: var(--dark-text-primary);
  background-color: var(--dark-background–tertiary);
  /* border: 1px solid white; */
}

.dark-theme .bar-chart-wrapper {
  background-color: var(--dark-background–tertiary);
}

.dark-theme .patient-note-input {
  background-color: var(--dark-background–primary);
  color: white;
}

.dark-theme .ecg-session-item {
  background-color: var(--dark-background–secondary);
}

.dark-theme .weight-table,
.dark-theme .spirometry-table {
  color: var(--dark-text-secondary);
}
.dark-theme .celsius-char {
  color: var(--dark-text-primary);
}

.dark-theme .bp-daynight {
  color: var(--dark-text-primary);
}

.dark-theme .bp-info-span {
  color: var(--dark-text-primary) !important;
}

.dark-theme .bp-info-icon,
.dark-theme .ppg-icon {
  /* filter: invert(100%); */
  filter: brightness(150%);
}

.dark-theme .chart-vert-item,
.dark-theme .chart-item span {
  color: var(--dark-text-primary) !important;
}

.dark-theme .continuous-panel-text,
.dark-theme .gait-view {
  color: var(--dark-text-primary);
}

.dark-theme .download-wiff-span,
.dark-theme .hplink {
  color: var(--dark-theme-link-color);
}

.dark-theme .voucher-icon {
  filter: invert(40%) brightness(200%);
}

.print-voucher-page {
  background-color: white !important;
  background: white !important;
}
.dark-theme .join-page {
  color: var(--dark-text-primary);
  background-color: var(--dark-background–primary);
}
.join-page {
  color: black;
  background-color: white;
}

.dark-theme .notes-app {
  color: var(--dark-text-primary);
}

.dark-theme .alarm-range-placeholder {
  background-color: var(--dark-background–primary);
}

.dark-theme .alarm-logo {
  filter: invert(100%);
}

.dark-theme .os-icon {
  filter: invert(60%);
}

.dark-theme .patient-range-title {
  color: var(--dark-text-primary);
}

.dark-theme .alarm-label {
  color: var(--dark-text-primary);
  /* opacity: 1 !important; */
}

.dark-theme .range-label {
  color: var(--dark-text-primary) !important;
}

.dark-theme .range-buttom {
  color: black;
}

.dark-theme .loading-spinner {
  filter: invert(100%);
}

.dark-theme .melokura {
  color: var(--dark-text-primary);
}

.dark-theme .psd-reset,
.dark-theme .edit-label {
  color: var(--dark-text-primary);
  opacity: 0.9;
}

.dark-theme .gait-ruler-label {
  color: var(--dark-text-primary);
  opacity: 1 !important;
}

.dark-theme .compliance-date,
.dark-theme .priority-square {
  filter: brightness(76%);
}

.dark-theme .gait-img {
  filter: invert(80%);
}

.dark-theme .normal-text {
  color: var(--dark-text-primary);
}

.dark-theme .sleep-vert-label,
.dark-theme .sleep-top-placeholder,
.dark-theme .sleep-bottom-placeholder,
.dark-theme .sleep-tick-label {
  color: var(--dark-text-primary);
  /* background-color: var(--dark-background–tertiary); */
  background-color: transparent;
}

.dark-theme .af-labels {
  color: var(--dark-text-primary);
  background-color: transparent;
}

.dark-theme .bubble {
  background: #fdfa72 !important;
}

.dark-theme .af-legend-background {
  background-color: var(--dark-background–tertiary);
  filter: brightness(200%);
}
/* layouts */

.big_tiles-layout .list-placeholder {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; */

  width: 100%;
  /* overflow: hidden; */
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.big_tiles-layout .patient-item {
  /* width: 24%; */
  /* max-height: 500px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.big_tiles-layout .patient-name {
  margin-bottom: 1rem;
}

.big_tiles-layout .patient-item-top {
  display: flex;
  height: 300px;
  flex-direction: column;
}

.big_tiles-layout .params-items-placeholder {
  display: grid !important;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  /* customized */
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-items: flex-start;
  gap: 0.2rem;
}

.big_tiles-layout .patient-item-bottom {
  display: grid !important;
  /* align-items: flex-start; */
  /* flex-direction: column; */
  height: 24px;
  grid-template-columns: repeat(2, 0.4fr);
  /* grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) ); */

  /* grid-template-rows: repeat(2, 1fr); */
}

.big_tiles-layout .temp-param-label {
  font-size: 1.1vh;
}

.big_tiles-layout .param-value-1 {
  font-size: 11px;
  line-height: normal;
}

.big_tiles-layout .bottom-link-item,
.big_tiles-layout .bottom-device-item {
  display: none;
}

.big_tiles-layout .bottom-item {
  margin-right: 0;
}

.big_tiles-layout .bottom-item-setting,
.big_tiles-layout .bottom-bell-item {
  display: none;
}

.big_tiles-layout .bottom-age-span {
  line-height: 14px !important;
  font-size: 12px !important;
}

.big_tiles-layout .SelectBraceletTool {
  margin-top: -0.74rem;
  margin-bottom: 1rem;
}

/* small tiles */

.small_tiles-layout .list-placeholder {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; */
  width: 100%;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(6, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
}

.small_tiles-layout .param-label {
  font-size: 12px;
  margin-top: 4px;
}

.small_tiles-layout .param-value {
  font-size: 14px;
  line-height: 12px;
}

.small_tiles-layout .param-icon {
  width: 20px;
}

.small_tiles-layout .patient-item {
  /* width: 90%; */
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.small_tiles-layout .name-section-placeholder {
  width: 180px;
}
.small_tiles-layout .patient-name {
  display: block;
  margin-bottom: 0.5rem;
  line-height: 1.2em;
  /* margin-top: 0; */
  font-size: 1em;
}

.small_tiles-layout .patient-item-top {
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  height: 160px;
  display: flex;
  flex-direction: column;
}

.small_tiles-layout .params-items-placeholder {
  display: grid !important;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  /* customized */
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(3, 1fr);  */
  justify-items: flex-start;
  gap: 0.8em;
}

.small_tiles-layout .patient-item-bottom {
  padding-left: 8px;
  /* display: none; */
  display: grid !important;
  /* align-items: flex-start; */
  /* flex-direction: column; */
  height: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.small_tiles-layout .bottom-item {
  margin-right: 0;
}

.small_tiles-layout .bottom-bell-item {
  display: none;
}

.small_tiles-layout .bottom-age-span {
  line-height: 10px !important;
  font-size: 10px !important;
}

.small_tiles-layout .setting-bell-icon,
.small_tiles-layout .too-long-logo,
.small_tiles-layout .blink_me,
.small_tiles-layout .bottom-bell-icon {
  width: 16px;
  height: 16px;
}

.small_tiles-layout .bottom-value {
  font-size: 10px;
  line-height: 14px;
}

.small_tiles-layout .SelectBraceletTool {
  margin-top: -0.34rem;
  margin-bottom: 1rem;
}

.small_tiles-layout .bottom-link-item,
.small_tiles-layout .bottom-device-item {
  display: none;
}

.small_tiles-layout .brace-select__control {
  min-height: 24px;
  height: 20px;
  font-size: 13px;
  align-items: center;
  align-content: center;
}

/* af section */

.hidden-af {
  display: none;
}

.af-patient-item-top {
  display: flex !important;
  flex-direction: row !important;
}

.af-list-placeholder {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; */
  width: 100%;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
}

.secret-question-selector {
  margin-bottom: 1rem;
  /* padding-inline: 1rem; */
}

.news-param-label {
  filter: contrast(50%);
}
